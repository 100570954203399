<script setup lang="ts">
const title = 'Privacy Policy - Your Data is Safe with EasyFun.gg'
const description = 'Your privacy matters at EasyFun.gg. Learn how we protect your data and respect your privacy while you play.'
useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})
</script>

<template>
  <div class="view-content">
    <div class="w-full flex flex-col pt-10">
      <h2 class="mrd-title" un-before="mrd-title-i">
        Privacy Policy
      </h2>
      <p class="mrd-content">
        [FunEase Network Technology Limited] (referred to as “us” or “we”) takes your privacy seriously and is committed to providing you with information to help you understand our privacy practices. This Privacy Policy (the “Policy”) discloses the privacy practices of our website[www.easyfun.gg](the “Site”), as well as related products and services we may offer to you (collectively referred to as the “Services”). This Policy covers how Personal Information that we receive or collect about you is used or shared. By using our Site and Services, you agree to the terms of this Policy as they may be amended from time to time.
      </p>
      <p class="mrd-content">
        We treat data that identifies you, or reasonably can be linked to you, as “Personal Information.” Data that has been de-identified or aggregated so that it cannot reasonably be linked to you is not Personal Information, and we may use that data for any purpose.
      </p>
      <h2 class="mrd-title" un-before="mrd-title-i">
        Personal Information We Collect From You
      </h2>
      <p class="mrd-content">
        We may collect the following Personal Information about you[1] :
      </p>
      <ul class="mrd-ul">
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Contact Information,</strong> such as name, postal address, phone, and email address.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Commercial Information,</strong> such as information relating to your purchases and use of our Services, including purchasing or consuming histories, tendencies or interests.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp; Internet or Similar Network Activity,</strong> such as your IP address, browser type, operating system and activity on our Site. We also collect Personal Information concerning your interaction with our advertising and other communications.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Location Information,</strong> such as information collected automatically when you access our Site.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp; Demographic Information,</strong> such as age or birthday, race or gender, salary range, education, marital status, occupation, or personal and online interests.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Inferences drawn from other personal information,</strong> such as profiles reflecting your preferences, characteristics, predispositions, attitudes and aptitudes.</li>
      </ul>
      <h2 class="mrd-title" un-before="mrd-title-i">
        How We Obtain Your Personal Information
      </h2>
      <p class="mrd-content">
        We obtain your Personal Information in the following ways:
      </p>
      <p class="mrd-content">
        <strong class="mrd-strong">From You: </strong>We obtain most of the Personal Information that we collect about you from you when you interact with our Site, including when you contact and communicate with us via forms contained on our Site and when you provide answers and information in response to surveys, quizzes, sweepstakes and contests.
      </p>
      <p class="mrd-content">
        <strong class="mrd-strong">From Our Partners: </strong>We may obtain Personal Information about you from our service providers or other partners, including marketing partners in the course of assisting us to providing you with our Site or Services. We also may utilize third party companies that utilize technology to independently document users’ consent provided on our Site signifying agreement to be contacted by us or our partners.<br>
        In addition, advertising agencies, advertising networks, and other companies who place ads on our Site may use tracking technologies to collect information about you.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Our Use of Personal Information
      </h2>
      <p class="mrd-content">
        We may use your Personal Information as follows:
      </p>
      <ul class="mt-4 mrd-ul">
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Provide Our Products and Services:</strong> We use Personal Information to furnish our Services, including to process and fulfill your selection or purchase of our Services and to improve the quality, safety and security of our Site and the Services</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Communicate with You:</strong> We use Personal Information to respond to communications, contact you about your transactions, promote our Services, provide other relevant information, and request information or feedback.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Personalize Your Experience:</strong> We utilize Personal Information regarding your use of our Site, including to personalize and enhance your experiences.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Internal Research:</strong> We use Personal Information to conduct internal research to develop, improve or repair our products, services or technology.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Security or Fraud Prevention:</strong> We use Personal Information to: (1) prevent, detect, protect against, or respond to security incidents, and (2) preserve the integrity or security of systems.</li>
        <li><strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Legal Compliance:</strong> We may use Personal Information to comply with applicable law or to comply with a lawful governmental request.</li>
      </ul>

      <p class="mrd-content">
        We strive to retain your Personal Information only for as long as reasonably necessary to fulfill the purpose(s) for which we obtained the information or as otherwise required by law.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Our Sharing of Personal Information
      </h2>
      <p class="mrd-content">
        We may share Personal Information with service providers who act on our behalf, our partners, or others operating at your direction or where legally necessary or appropriate.
      </p>

      <ul class="mt-4 mrd-ul">
        <li>
          <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Legal Affiliates:</strong> We may share information with our subsidiaries and legal affiliates.
        </li>
        <li>
          <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Service Providers:</strong> We engage third parties to act as our service providers and perform certain tasks on our behalf in connection with our Site and Services. The tasks performed by service providers include: (1) processing and storing Personal Information, (2) assisting with marketing communications and advertising, (3) customer service assistance; (4) providing technology services, including assisting with our Site. We may share all of types of Personal Information that we collect that are described above with service providers. We encourage service providers to take reasonable steps to ensure that they keep Personal Information confidential and only utilize Personal Information as permitted.
        </li>
        <li>
          <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Partners:</strong> We may partner with third parties to provide services and other offerings. For example, we may share your contact information with business partners related to content on our Site to provide you with information about products, services and special offers that may interest you. When you use our Site, we may share Personal Information with partners who match the information you share with online and offline marketing databases and those of its advertising partners for the purpose of enabling interest-based content and advertising by third parties unaffiliated with our Site. We also may share Personal Information with sponsors of surveys, contests or sweepstakes so that they may contact you in conjunction with the survey, contest or sweepstakes. We take reasonable steps to ensure that the entities to which we share your Personal Information keep the information confidential and only use it as permitted.
        </li>
        <li>
          <strong class="mrd-strong">&nbsp;•&nbsp;&nbsp;Other Legal Purposes:</strong> We also may share Personal Information: (1) with your consent, (2) to respond to subpoenas, court orders, or other legal process or otherwise as required or allowed by law or the competent governmental or judicial authorities; (3) to cooperate with law enforcement, government or regulatory entities, and safety or investigation authorities; (4) to protect our rights or property, or to protect the personal safety of users or the public; or (5) to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us is among the assets transferred.
        </li>
      </ul>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Use of Cookies and other Tracking Technologies
      </h2>
      <p class="mrd-content">
        Like many commercial websites, we analyze how visitors use our Site with "cookie" technology. A cookie is a small text file that is placed on your computer when you access our website and allows us to recognize you each time you visit the website. We may use cookies to (1) enhance or personalize your website usage and experience; (2) monitor website usage; and (3) improve the website and our services, including providing you with interest-based ads. If you choose, you can set your browser to reject cookies or you can manually delete individual cookies or all of the cookies on your computer by following your browser’s help file directions. However, if your browser is set to reject cookies or you manually delete cookies, you may have some trouble accessing and using some of the pages and features on our Site. Please note that browser-management tools for cookies are outside of our control, and we cannot guarantee their effectiveness. To manage these settings click here: <NuxtLink class="color-green-5" to="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank">
          Google Chrome Help
        </NuxtLink>
      </p>

      <p class="mrd-content">
        We may also use web beacons (sometimes called single-pixel gifs, clear gifs or action tags) on our Site, in our emails, and in our advertisements on other websites. Web beacons are tiny graphic images that are used to collect information about your visit to the website, such as the pages you view and the features you use, as well as information about whether you open and/or act upon one of our emails or advertisements. We may also collect the URL of the website you visited immediately before coming to our website. Web beacons help us analyze the behavior of visitors to our Site and measure the effectiveness of the Site and our advertising.
      </p>

      <p class="mrd-content">
        We may work with service providers and other third parties that help us track, collect and analyze the information collected in cookies and web beacons. We may combine the information we collect with other information we have collected from you or information from other sources.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Security of Your Personal Information
      </h2>
      <p class="mrd-content">
        We use commercial reasonably physical, technical and administrative security measures to reduce the risk of loss, misuse, unauthorized access, and disclosure of data. Please note, however, that no security measures are perfectly secure, and we are unable to guarantee the security of your Personal Information.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Third Party Websites and Applications
      </h2>
      <p class="mrd-content">
        Our Site may contain links to websites or applications hosted by third parties. This Policy only applies to websites and applications within our control and does not apply to any third party sources. Any data collected through third party websites or applications shall be subject to the privacy practices of the third party, and you should review the privacy policies of these third parties prior to sharing any data.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Your Rights and Choices Regarding Your Data
      </h2>
      <p class="mrd-content">
        We give you certain choices regarding our use and disclosure of your Personal Information for promotional purposes. You may opt-out from receiving electronic communications from us by contacting us as detailed in the “Contact Us” section below. If you no longer want to receive promotional-related emails from us, you may opt-out according to instructions in such communications.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Additional Information for Certain Jurisdictions
      </h2>
      <p class="mrd-content">
        We provide additional information about the privacy, collection, and use of personal information of prospective and current customers located in certain jurisdictions.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        EU Data Subjects
      </h2>
      <p class="mrd-content">
        The European Union’s General Data Protection Regulation (“GDPR”) provides EU data subjects with the additional rights described below.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Use of Personal Information
      </h2>
      <p class="mrd-content">
        We generally act as a data controller of EU data subject’s Personal Information submitted to us. We rely on that Personal Information for the legitimate commercial purposes of providing our Site and Services to you. Where we need to collect Personal Information by law or under the terms of a contract we have with you, and you fail to provide that Personal Information when requested, we may not be able to provide you with our Services. We consider and balance any potential impact on you and your rights before we process your Personal Information. We do not use your Personal Information for activities where our interests are overridden by the impact on you unless we have your consent or are otherwise required or permitted to by law. In certain circumstances, we act as a data processor of third-party partners, and, in those circumstances, we process your Personal Information for the purposes set out in this Policy consistent with the data processing terms of those partners.
      </p>

      <p class="mrd-content">
        We also may rely on your consent to collect, use or disclose your Personal Information. If we rely on your consent to process your Personal Information, you have the right to withdraw your consent at any time by contacting us at the information below. We will only use your Personal Information for the purposes for which we collected it unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your Personal Information for an unrelated purpose, we will notify you and explain the legal basis that allows us to do so.
      </p>

      <p class="mrd-content">
        If you are located in the EU and have any complaints regarding our privacy practices as a data controller, you have the right to make a complaint to your local Supervisory Authority. However, we would appreciate the opportunity to address your concerns before you contact your Supervisory Authority, so please contact us first at the information below.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        Your Rights
      </h2>
      <p class="mrd-content">
        If you would like to request a copy of your Personal Information being held by us, request that Personal Information be deleted, or to update and/or correct your Personal Information, or request that we provide a copy to another data controller, please contact us at the information contained in the “Contact Us” section below. We will need enough information to ascertain your identity as well as the nature of your request. We will aim to respond to your request within one (1) calendar month of receipt of the request. Where we were unable to do so within the calendar month, we will notify you of the soonest practicable time within which we can respond to your request (and within three (3) months from the date of your request). Please note that there are certain exemptions and restrictions of these rights under the European data privacy lWe that enable personal information to be retained, processed or withheld from access and we will inform you of these exemptions and restrictions, if applicable.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        International Data Transfers
      </h2>
      <p class="mrd-content">
        We offer EU Model Clauses, also known as Standard Contractual Clauses, to meet the adequacy and security requirements for our customers that operate in the European Union and the United Kingdom, and make transfers of Personal Information from those jurisdictions to other countries that lack adequacy determinations. Please contact us as detailed in the “Contact Us” section below for more information.
      </p>

      <h2 class="mrd-title" un-before="mrd-title-i">
        California Residents
      </h2>
      <p class="mrd-content">
        The right to know what Personal Information we have collected, used, disclosed and sold about you. To submit a request to know, you may contact us as detailed in the “Contact Us” section below.
      </p>
      <p class="mrd-content">
        The right to request that we delete any Personal Information we have collected about you, subject to certain exceptions. To submit a request for deletion, you may contact us as detailed in the “Contact Us” section below.
      </p>
      <p class="mrd-content">
        The right to opt out of the sale of your Personal Information. To submit a request to opt-out, please contact us as detailed in the “Contact Us” section below. We have sold Personal Information when engaging in personalized or targeted advertising. In connection with such advertising, we may share or sell the following categories of information with advertising networks and providers, advertisers, analytics providers, and social media networks, which may be considered a sale under California law: Device information and identifiers, such as unique advertising identifiers and cookies, and IP address; usage information, such as browsing history; location information, such as city; and inference data.
      </p>
      <p class="mrd-content">
        When you submit a request to know or delete, we will verify your identity. We may ask for your email address or other information that we believe you will know that will allow us to verify that the request is coming from the person named in the request. If we are unable to appropriately verify your identity, your request may be denied. You also may designate an authorized agent to make a request on your behalf, subject to appropriate verification and other applicable legal requirements. Your exercise of the above rights will have no adverse effect on the price and quality of our goods or services.
      </p>
      <p class="mrd-content">
        In addition to the rights described above, if you are a California resident, you may request information about our disclosure of personal information to third parties or affiliated companies for their direct marketing purposes. To make such a request, please contact us as set forth in the “Contact Us” section below.
      </p>
    </div>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Japanese Residents
    </h2>
    <h3 class="mrd-min-title">
      Use of Personal Information
    </h3>

    <p class="mrd-content">
      We share personal information with third parties only to the extent described under “Our Sharing of Personal Information” above and in accordance with the Act on Protection of Personal Information.
    </p>

    <p class="mrd-content">
      When we share your personal information with third parties outside of Japan,, we require the recipients to adopt personal information security measures to protect your personal information.
    </p>

    <h3 class="mrd-min-title">
      Your Rights
    </h3>

    <p class="mrd-content">
      You may have the right to request access to or delete your personal information in accordance with applicable law. If you wish to submit a data subject access request, you may contact us as detailed in the “Contact Us” section below.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      South Korean Residents
    </h2>
    <p class="mrd-content">
      We do not knowingly collect personal information from children under the age of 14 without the consent of the child's parent or guardian.
    </p>

    <h3 class="mrd-min-title">
      Use of Personal Information
    </h3>
    <p class="mrd-content">
      We share personal information with third parties only to the extent described under “Our Sharing of Personal Information” above and in accordance with the Act on Protection of Personal Information.
    </p>

    <p class="mrd-content">
      When we share your personal information with third parties outside of South Korea, we require the recipients to adopt personal information security measures to protect your personal information.
    </p>

    <h3 class="mrd-min-title">
      Deletion of Personal Information
    </h3>

    <p class="mrd-content">
      We will delete your personal information (including after closure of your We account) as described in this Privacy Policy. When deleting personal information, We will take standard commercially reasonable measures to make the personal information practically irrecoverable or irreproducible. The specific manner of deletion will depend on the information being deleted, how the information was collected and stored, and your interactions with us. Electronic documents or files containing personal information will be deleted using a technical method that makes recovery or retrieval of such information practically impossible or renders the data no longer personally identifiable. Non-electronic documents or files containing personal information will be shredded, incinerated, or both.
    </p>

    <h3 class="mrd-min-title">
      Retention of Personal Information
    </h3>

    <p class="mrd-content">
      We keep your personal information to enable your continued use of We Offerings, for as long as it is required in order to fulfill the relevant purposes described in this Privacy Policy, as may be required by law (including for tax and accounting purposes as may be required by the Framework Act on National Taxes of Republic of Korea or other laws), or as otherwise communicated to you. How long we retain specific personal information varies depending on the purpose for its use, and we will delete your personal information in accordance with applicable law.
    </p>

    <p class="mrd-content">
      If you have any privacy questions or requests, you may contact us as detailed in the “Contact Us” section below.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Personal Information Concerning Children
    </h2>

    <p class="mrd-content">
      Our Site is not intended for anyone under the age of 【16】. We do not knowingly collect Personal Information from individuals under the age of 16, unless the Personal Information is submitted with verifiable parental consent.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Updates to the Policy
    </h2>

    <p class="mrd-content">
      We may update this Policy periodically as we offer new products and services, and as our business, technology, and applicable We change. You can determine when this Policy was last revised by referring to the “Last updated” referenced below. Any changes will become effective upon posting of the revised Policy.
    </p>

    <h2 class="mrd-title" un-before="mrd-title-i">
      Contact Us
    </h2>

    <p class="mrd-content">
      Company:FunEase Network Technology Limited Company<br>
      Address：6/F Manulife Place, 348 Kwun Tong Road, Kowloon, Hong Kong.<br>
      Email：contact@easyfun.gg<br>
    </p>
  </div>
</template>
